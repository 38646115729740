<template>
  <div class="menus">
    <el-container>
      <el-header>
        <div class="titlename">
          <img
            src="@/assets/image/logao.png"
            alt=""
          />农产品集采平台后台管理系统
        </div>
        <div class="outtab">
          <el-popover placement="top" width="160" v-model="visible">
            <div class="tiimgs">
              <img src="@/assets/image/my.png" alt="" />
              <div>{{ info.nickname }}</div>
              <div class="btn pointer" @click="latout">退出登录</div>
            </div>
            <div class="imgti pointer" slot="reference">
              <img src="@/assets/image/my.png" alt="" />
              <span>{{ info.nickname }}</span>
              <i class="el-icon-arrow-down"></i>
            </div>
          </el-popover>
        </div>
      </el-header>
      <el-container>
        <el-aside>
          <el-menu
            :default-active="$route.path"
            class="el-menu-vertical-demo"
            router
            :unique-opened="true"
            @select="handleSelect"
          >
            <div v-for="item in menuslist" :key="item.id">
              <el-submenu
                :index="item.path"
                v-if="item.children && item.children.length > 0"
              >
                <template slot="title">
                  <img :src="getPath(item.icon)" alt="" class="imgs" />
                  <span>{{ item.name }}</span>
                </template>
                <el-menu-item
                  :index="list.path"
                  v-for="list in item.children"
                  :key="list.id"
                  >{{ list.name }}
                </el-menu-item>
              </el-submenu>
              <div v-else>
                <el-menu-item :index="item.path">
                  <img :src="getPath(item.icon)" alt="" class="imgs" />
                  <span slot="title">{{ item.name }}</span>
                </el-menu-item>
              </div>
            </div>
          </el-menu>
        </el-aside>
        <el-main>
          <div class="sbts">
            <keep-alive v-if="isRouterAlive">
              <router-view v-if="$route.meta.keepAlive"></router-view>
            </keep-alive>
            <transition name="fade" mode="out-in">
              <router-view v-if="!$route.meta.keepAlive" />
            </transition>
          </div>
        </el-main>
      </el-container>
    </el-container>
  </div>
</template>
<script>
// import { getCheckImgExists } from "@/utils/tools/base";

export default {
  name: "menus",
  components: {},
  provide() {
    return { reload: this.reload };
  },
  data() {
    return {
      visible: false,
      menuslist: [],
      info: "",
      isRouterAlive: true,
      refreshlist: ["/Payment", "/Receiving", "/ReceivingPrint"],
    };
  },
  methods: {
    reload() {
      this.isRouterAlive = false;
      this.$nextTick(() => (this.isRouterAlive = true));
    },
    /**
     * 获得图标路径
     */
    getPath(path) {
      // 默认图标
      const defaultUrl = require("@/assets/image/huo.png");
      // console.log("🆒 getPath", path, defaultUrl);
      // const res = (getCheckImgExists(url) && url) || defaultUrl;
      try {
        const url = path && require(`@/assets/image/${path}`);
        return url;
      } catch (err) {
        return defaultUrl;
      }
    },
    handleSelect(key, keyPath) {
      console.log(key, keyPath);
      if (this.refreshlist.includes(key)) {
        sessionStorage.removeItem("Paymentsearch");
        this.$store.commit("setRefresh", true);
      }
    },
    latout() {
      sessionStorage.setItem("userInfo", "");
      sessionStorage.setItem("token", "");
      sessionStorage.removeItem("windowid");
      this.$message({
        message: "退出成功",
        type: "success",
      });
      this.$router.push({
        path: "/",
      });
    },
  },
  created() {
    this.info = JSON.parse(sessionStorage.getItem("userInfo"));
    this.menuslist = JSON.parse(sessionStorage.getItem("menu"));
    console.log(this.menuslist);
  },
};
</script>
<style lang="less" scoped>
.menus {
  height: 100vh;

  .el-header {
    display: flex;
    align-items: center;
    padding: 0;
    height: 70px !important;
    background: #222e44;

    .titlename {
      display: flex;
      align-items: center;
      justify-content: center;
      color: #fff;
      padding-left: 9px;
      box-sizing: border-box;
      font-size: 22px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #ffffff;

      img {
        width: 44px;
        height: 44px;
        margin-right: 9px;
      }
    }

    .title {
      box-sizing: border-box;
      padding: 0 20px;
      height: 100%;

      .list {
        color: #fff;
        height: 100%;
        display: flex;
        align-items: center;
        padding: 0 20px;
        box-sizing: border-box;
      }

      .list:hover {
        background-color: #172033;
      }
    }

    .outtab {
      flex: 1;
      justify-content: flex-end;
      padding-right: 41px;
      box-sizing: border-box;
      display: flex;
      align-items: center;
      font-size: 18px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #f2f2f2;

      img {
        width: 38px;
        height: 38px;
        margin-right: 8px;
      }

      .imgti {
        display: flex;
        align-items: center;
      }
    }
  }

  .el-aside {
    max-width: 250px;
    height: calc(100vh - 70px);
    background-color: #222e44 !important;

    .portrait {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      margin: 20px 0;

      div {
        width: 60px;
        height: 60px;
        background-color: #172033;
        border-radius: 50%;
        margin-bottom: 10px;

        img {
          width: 100%;
          height: 100%;
          border-radius: 50%;
        }
      }
    }

    background: #fff;

    /deep/ .el-submenu__title {
      background: #222e44;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #ffffff;
      font-size: 16px;
    }

    .el-menu-item {
      background: #222e44;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #ffffff;
      font-size: 16px;
    }

    /deep/ .is-active {
      background: #172033;
      color: #fff;
    }

    .el-menu {
      border-right: 0;
      background: #222e44;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #ffffff;
      font-size: 16px;
    }

    /deep/ .el-menu--inline {
      .el-menu-item {
        padding-left: 55px !important;
      }
    }
  }

  .el-main {
    height: calc(100vh - 70px);
    padding: 0;

    background: #f5f6f8;

    .tabs {
      height: 40px;
      background: #ffffff;
      margin: 6px;
      display: flex;
      align-items: center;
    }

    .sbts {
      height: calc(100vh - 70px);
      // height: calc(100vh - 122px);
    }
  }

  .imgs {
    width: 20px;
    height: 23px;
    margin-right: 15px;
    vertical-align: middle;
  }
}

.el-tag {
  margin: 0px 5px;
  border: 0;
}

.tiimgs {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 15px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #333333;

  img {
    width: 38px;
    height: 38px;
    margin-bottom: 4px;
  }

  .btn {
    background: #ffffff;
    border-radius: 2px;
    border: 1px solid #21c2ac;
    font-size: 13px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #21c2ac;
    line-height: 13px;
    width: 94px;
    height: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 13px;
  }
}
</style>
